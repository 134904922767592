/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.nav a').each(function() {
          $(this).wrapInner('<span />');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var isMarqueeActive = false;

        function init(){
          if (window.innerWidth <= BREAKPOINT.large && !isMarqueeActive) {
            initMarquee();
          } else if (window.innerWidth > BREAKPOINT.large && isMarqueeActive) {
            destroyMarquee();
          }
        }

        function initMarquee(){
          $('.js-marquee > div').marquee({
            duration: 10000,
            gap: 50,
            delayBeforeStart: 1000,
            direction: 'left',
            duplicated: true,
            startVisible: true
          });

          isMarqueeActive = true;
        }

        function destroyMarquee(){
          $('.js-marquee > div').marquee('destroy');
          isMarqueeActive = false;
        }

        init();
        $(window).on('resize', _.debounce(init, 250));

        $(window).on('load', function() {
          $('.intro').addClass('is-active');

          setTimeout(function() {
            $('.intro').hide();
          }, 1000);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'artists': {
      init: function() {
        // JavaScript to be fired on the about us page
        function resizeCard() {
          $('.roster-item').each(function(){
            $(this).attr('style', '');

            var height = Math.floor($(this).height());

            $(this).height(height);
          });
        }

        resizeCard();
        $(window).on('resize', _.debounce(resizeCard, 250));

        var RosterItem = function(item) {
          this.$item = $(item);
          this.$video = $(item).find('video');

          $(item).on('init', {app: this}, this.onInit);
          $(item).on('calculate', {app: this}, this.onCalculate);

          $(item).trigger('init');
        };

        RosterItem.prototype.onInit = function(event) {
          setInterval(function() {
            event.data.app.$item.trigger('calculate');
          }, 100);
        };

        RosterItem.prototype.onCalculate = function(event) {
          var app = event.data.app;
          var top = app.$item.offset().top;
          var bottom = top + app.$item.height();
          var isVisible = getVisibility(top, bottom);
          var video = app.$video.get(0);

          if (isVisible && video.paused) {
            video.play();
          } else if (!isVisible && !video.paused) {
            video.pause();
          }
        };

        function getVisibility(top, bottom) {
          top = top - $(window).scrollTop();
          bottom = bottom - $(window).scrollTop();

          if (
            top <= window.innerHeight &&
            bottom > 0
          ) {
            return true;
          }

          return false;
        }

        $('.roster-item').each(function() {
          new RosterItem(this);
          return this;
        });
      }
    },
    'events': {
      init: function() {
        $('.event-group').each(function() {
          $(this).last_items();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
