;(function($, document, window, undefined) {
  var LastItems = function($grid) {
    this.$grid = $grid;

    //Methods
    this.calculate = this.calculate.bind(this);

    //Events
    this.$grid.on('init:last_items', this.calculate);
    $(window).on('resize', _.debounce(this.calculate, 250));

    //Initialise
    this.$grid.trigger('init:last_items');
  };

  LastItems.prototype.calculate = function() {
    var $grid  = this.$grid;
    var $items = $($grid.children().get().reverse());
    var found  = false;
    var offset;

    if (!$items.length) {
      return;
    }

    offset = $items.first().offset().top;

    $items.each(function() {
      var $item = $(this);

      if (!found) {
        if (offset === $item.offset().top) {
          $item.addClass('is-last');
          return;
        }

        found = true;
      }

      $item.removeClass('is-last');
    });
  };

  $.fn.last_items = function() {
    new LastItems(this);
    return this;
  };
})(jQuery, document, window);
