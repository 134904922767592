;(function($, document, window, undefined) {
  var $header = $('.header');
  var isHeaderActive = false;
  var scrollTop;
  var inactiveTimeout = null;

  setInterval(function() {
    var currentScrollTop = $(window).scrollTop();

    if (currentScrollTop === scrollTop) {
      return;
    }

    if (currentScrollTop < scrollTop && !isHeaderActive) {
      isHeaderActive = true;
      $header.addClass('is-active');

      if (inactiveTimeout) {
        clearTimeout(inactiveTimeout);
        removeTimeout = null;
      }
    } else if (currentScrollTop > scrollTop && isHeaderActive) {
      inactiveTimeout = setTimeout(function(){
        isHeaderActive = false;
        $header.removeClass('is-active');
      }, 1000);
    }

    scrollTop = currentScrollTop;
  }, 40);
})(jQuery, document, window);
